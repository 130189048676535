import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
import exampleDatePicked from '../images/example-date-picked.gif';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`JavaScript Date Overflow Edge Case`}</h1>
    <p>{`Today's date is 1/30/2025 (it's important to the story, I promise).`}</p>
    <p>{`Today I was notified that one of the end-to-end UI tests for an app that I maintain was failing.`}</p>
    <h2><HashLink id="test-context" to="/js-date-quirk#test-context" mdxType="HashLink">{`What does the test do?`}</HashLink></h2>
    <p>{`For the sake of brevity, let's simplify things a bit and say this particular test case uses a date picker to choose a future date, submits a form and validates that the submitted date renders properly on the screen.`}</p>
    <p>{`First, the test will navigate to the target page with our form.`}</p>
    <p>{`Next, the test will fill out the date picker. Since we need a future date we:`}</p>
    <ol>
      <li parentName="ol">{`open the picker`}</li>
      <li parentName="ol">{`click the button to navigate to the next month`}</li>
      <li parentName="ol">{`click on the 10th of the month`}</li>
    </ol>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={exampleDatePicked} alt="Using a date picker to select 02/10/2025" width="60%" height="100%" />
    </div>
    <p>{`Other than the fact that there is a 10th day of every month, the decision to use `}<inlineCode parentName="p">{`10`}</inlineCode>{` was arbitrary. On this particular day, the test scenario is expected to save 02/10/2025.`}</p>
    <p>{`The next step submits the form and waits for the server's successful response that everything was properly saved.`}</p>
    <p>{`At this point, the application renders the form's submitted questions/answers on the screen. Now we want to assert that the date we selected is properly showing on the page.`}</p>
    <p>{`This is the logic we use to get our expected date string:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const expectedDate = new Date();

expectedDate.setMonth(expectedDate.getMonth() + 1);
expectedDate.setDate(10);

const expectedDateString = expectedDate.toLocaleDateString('en-US', {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
});
`}</code></pre>
    <h2><HashLink id="the-failure" to="/js-date-quirk#the-failure" mdxType="HashLink">{`The test failure`}</HashLink></h2>
    <p>{`When we assert that `}<inlineCode parentName="p">{`expectedDate`}</inlineCode>{` is in the DOM we get the following error.`}</p>
    <blockquote>
      <p parentName="blockquote">{`expected '02/10/2025' to equal '03/10/2025'`}</p>
    </blockquote>
    <p>{`So the DOM correctly renders 02/10/2025 but the test code is expecting a date that is a month later. How could this be?`}</p>
    <h2><HashLink id="the-solution" to="/js-date-quirk#the-solution" mdxType="HashLink">{`Uncovering the solution`}</HashLink></h2>
    <p>{`If you run the code step-by-step you see that the `}<inlineCode parentName="p">{`getMonth()`}</inlineCode>{` call returns `}<inlineCode parentName="p">{`0`}</inlineCode>{` as expected since the number representation of a month in JS is 0-indexed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`console.log(expectedDate.getMonth()); // --> 0
`}</code></pre>
    <p>{`So that's fine. Let's keep going and check the output of the following subset of code.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`expectedDate.setMonth(expectedDate.getMonth() + 1);

const expectedDateString = expectedDate.toLocaleDateString('en-US', {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
});

console.log(expectedDateString); // --> '03/02/2025' 🤯
`}</code></pre>
    <p>{`So why is this happening?`}</p>
    <p>{`We are trying to increment the month by 1 on January 30th but there are only 28 days in February so February 30th does not exist. In this case, the JavaScript `}<inlineCode parentName="p">{`Date`}</inlineCode>{` overflows and sets the date to March 2nd (the next valid date).`}</p>
    <p>{`Once I realized this, the fix was simple; just set the day before setting the month.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// broken on dates like 1/29/25, 1/30/25, etc.
expectedDate.setMonth(expectedDate.getMonth() + 1);
expectedDate.setDate(10);

// fixed
expectedDate.setDate(10);
expectedDate.setMonth(expectedDate.getMonth() + 1);
`}</code></pre>
    <p>{`This way, we set 1/30/25 to 1/10/25 then set 1/10/25 to 2/10/25. Problem solved.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      